import React from "react"
import Layout from "../components/layout"
import { Helmet } from 'react-helmet'

export default () => (
  <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Pandas...</title>
      <link rel="canonical" href="http://mysite.com/example" />
    </Helmet>
    <h1>Amazing Pandas Eating Things</h1>
    <div>
      <img
        src="https://2.bp.blogspot.com/-BMP2l6Hwvp4/TiAxeGx4CTI/AAAAAAAAD_M/XlC_mY3SoEw/s1600/panda-group-eating-bamboo.jpg"
        alt="Group of pandas eating bamboo"
      />
    </div>
  </Layout>
)
